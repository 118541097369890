<template>
  <div class="column">
    <h3 class="title is-3 is-marginless">Reserve Your Expert Question</h3>
    <div class="columns">
      <div class="column">
        <span>Select your expert question to answer below.
          Only one participant per question, so reserve your space now.
          Your selection will be reserved for five days.
        </span>
        <hr>
        <div class="columns is-mobile is-multiline">
          <ExpertCategory
            class="column is-4-desktop is-6-touch"
            v-for="category in categories"
            :key="category.title"
            :category="category"
            @new-value="updateSelected"
          />
        </div>
      </div>
      <div class="column is-3 is-hidden-mobile" :class="{ 'is-hidden': confirmed.length === 0 }">
        <ConfirmedExperts :confirmed="confirmed" />
      </div>
    </div>
    <div class="columns container alternative-face">
      <div class="column is-8 is-offset-2">
        <p class="is-marginless has-text-weight-bold is-size-7">OR</p>
        <p class="has-text-weight-light is-size-5">
          Create your own expert question to answer:
        </p>
        <div class="field has-text-left">
          <div class="control">
            <FormInput
              :field="{
                name: 'customAskTheExpert',
                type: 'text',
                validation: {
                  required: false,
                },
              }"
              @click.native="selected = null"
            />
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FormInput } from 'djx-form';

export default {
  data() {
    return {
      taken: [],
      confirmed: [],
      selected: null,
    };
  },
  computed: {
    ...mapState({
      rawCategories: 'asktheexpert',
      projectId: 'project_id',
    }),
    categories() {
      return this.rawCategories.map((category) => ({
        title: category,
        taken: this.taken.includes(category),
        selected: this.selected === category,
      }));
    },
  },
  methods: {
    updateSelected(newValue) {
      if (!this.taken.includes(newValue)) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    // Get the reserved questions from the API and populate this.taken and this.confirmed
    window.fetch(`https://dj5-api.datajoe.com/api/survey/experts/${this.projectId}`, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => res.json())
      .then(({ taken, confirmed }) => {
        this.taken = taken;
        this.confirmed = confirmed;
      });
  },
  components: {
    FormInput,
    ExpertCategory: () => import('@/components/AskTheExpert/ExpertCategory.vue'),
    ConfirmedExperts: () => import('@/components/AskTheExpert/ConfirmedExperts.vue'),
  },
};
</script>
